import React from 'react';
import { PageProps } from '../typing/Page';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import styled from '@emotion/styled';
import { border, breakpoints, color, fontSize, fontWeight, gutter, map, spacer } from '../styles/variables';
import { breakpointUp } from '../styles/mq';
import { breakLines } from '../utils/strings';
import Contact from '../components/Contact';
import Pin from '../img/pin.module.svg';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getTranslation } from '../utils/translations';

type StyledSectionProps = {
	hasBorder?: boolean;
};

type StyledTextProps = {
	isBold?: boolean;
};

const StyledSection = styled.section`
	padding-top: ${({ hasBorder }: StyledSectionProps) => (hasBorder ? spacer['9'] : '0')};
	border-top: ${({ hasBorder }: StyledSectionProps) => (hasBorder ? border.full : 'none')};

	&:not(:last-child) {
		margin-bottom: ${spacer['9']};

		${breakpointUp('md')} {
			margin-bottom: ${spacer['11']};
		}
	}

	${breakpointUp('md')} {
		padding-top: ${({ hasBorder }: StyledSectionProps) => (hasBorder ? spacer['11'] : '0')};
		font-size: ${fontSize.md};
	}
`;

const StyledGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${spacer['8']};

	${breakpointUp('md')} {
		grid-template-columns: 1fr 1fr;
		grid-gap: ${gutter.md.full};
	}
`;

const StyledTitle = styled.h2`
	margin-bottom: ${spacer['5']};
	font-size: ${fontSize.lg};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['7']};
		font-size: ${fontSize.h2};
	}

	> small {
		font-size: ${fontSize.sm};
		color: ${color.textMuted};

		${breakpointUp('md')} {
			font-size: ${fontSize.lg};
		}

		&:before {
			content: ' / ';
			font-size: ${fontSize.lg};
			font-weight: ${fontWeight.normal};
			color: ${color.text};

			${breakpointUp('md')} {
				font-size: ${fontSize.h2};
			}
		}
	}
`;

const StyledText = styled.p`
	margin-bottom: 0;
	font-size: ${fontSize.base};
	font-weight: ${({ isBold }: StyledTextProps) => (isBold ? fontWeight.bold : fontWeight.normal)};

	${breakpointUp('md')} {
		font-size: ${fontSize.md};
	}
`;

const StyledCaption = styled.span`
	display: block;
	margin-bottom: ${spacer['4']};
	font-size: ${fontSize.xs};
	color: ${color.textSemimuted};

	${breakpointUp('md')} {
		font-size: ${fontSize.sm};
		margin-bottom: ${spacer['10']};
	}
`;

const StyledMapContainerOuter = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: ${map.ratio};

	${breakpointUp('md')} {
		margin-bottom: 0;
	}

	> svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -100%);
	}
`;

const StyledMapContainerInner = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
	background-color: ${color.gray['16']};

	> img {
		width: 100%;
		height: auto;
	}
`;

type Address = {
	title: string;
	subtitle: string;
	address: string;
	caption: string;
	image: string;
	link: string;
};

type Contacts = {
	name: string;
	function: string;
	phone: string;
	email: string;
	translations: string;
	image: string;
};

type ContactsData = {
	invoice: string;
	bank: string;
	addresses: {
		main: Address;
		remote: Address;
	};
	contacts: Contacts[];
};

const ContactsTemplate: React.FC<PageProps<ContactsData, GatsbyTypes.ContactsQuery>> = ({ path, data, pageContext }) => {
	return (
		<Layout
			path={path}
			pageContext={pageContext}
		>
			<PageTitle title={pageContext.title} />
			<StyledSection>
				<StyledGrid>
					{Object.keys(pageContext.data.addresses).map(key => {
						const addressKey = key as keyof typeof pageContext.data.addresses;
						const address = pageContext.data.addresses[addressKey];
						const imgDef = data.addressesImages.edges.find(edge => edge.node.relativePath === address.image);

						return (
							<a
								href={address.link}
								key={addressKey}
								target="_blank"
								rel="noreferrer"
							>
								<StyledTitle>
									{address.title}
									<small>{address.subtitle}</small>
								</StyledTitle>
								<StyledText
									isBold={true}
									dangerouslySetInnerHTML={{ __html: breakLines(address.address) }}
									css={{ marginBottom: spacer['4'] }}
								/>
								<StyledCaption>{address.caption}</StyledCaption>
								{imgDef?.node.childImageSharp?.gatsbyImageData && (
									<StyledMapContainerOuter>
										<StyledMapContainerInner>
											<GatsbyImage
												image={{
													...imgDef?.node.childImageSharp?.gatsbyImageData,
													sizes: `(max-width: ${breakpoints.sm - 1}px) calc(100vw - (${gutter.full} * 2)), (max-width: ${breakpoints.xxxl - 1}px) calc((100vw - (${gutter.md.full} * 3)) / 2), calc((99rem - (${gutter.md.full} * 3)) / 2)`,
												}}
												alt={address.title}
											/>
										</StyledMapContainerInner>
										<Pin />
									</StyledMapContainerOuter>
								)}
							</a>
						);
					})}
				</StyledGrid>
			</StyledSection>
			<StyledSection hasBorder={true}>
				<StyledGrid>
					{pageContext.data.contacts.map(contact => {
						const imgDef = data.contactsImages.edges.find(edge => edge.node.relativePath === contact.image);

						return (
							<Contact
								name={contact.name}
								companyFunction={contact.function}
								phone={contact.phone}
								email={contact.email}
								image={imgDef?.node.childImageSharp?.gatsbyImageData}
								key={contact.name}
							/>
						);
					})}
				</StyledGrid>
			</StyledSection>
			<StyledSection hasBorder={true}>
				<StyledGrid>
					<div>
						<StyledTitle>{getTranslation(data, 'billingInfo')}</StyledTitle>
						<StyledText dangerouslySetInnerHTML={{ __html: breakLines(pageContext.data.invoice) }} />
					</div>
					<div>
						<StyledTitle>{getTranslation(data, 'bankConnection')}</StyledTitle>
						<StyledText dangerouslySetInnerHTML={{ __html: breakLines(pageContext.data.bank) }} />
					</div>
				</StyledGrid>
			</StyledSection>
		</Layout>
	);
};

export const query = graphql`
	query Contacts($lang: String!, $addressesImages: [String!], $contactsImages: [String!]) {
		translations: allFile(filter: { name: { eq: $lang }, extension: { eq: "json" } }) {
			nodes {
				childTranslationsJson {
					billingInfo
					bankConnection
				}
			}
		}
		addressesImages: allFile(filter: { relativePath: { in: $addressesImages } }) {
			edges {
				node {
					id
					relativePath
					childImageSharp {
						gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
					}
				}
			}
		}
		contactsImages: allFile(filter: { relativePath: { in: $contactsImages } }) {
			edges {
				node {
					id
					relativePath
					childImageSharp {
						gatsbyImageData(width: 120, height: 120, placeholder: BLURRED, layout: FIXED)
					}
				}
			}
		}
	}
`;

export default ContactsTemplate;
