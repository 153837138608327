import React from 'react';
import { getRemSize } from '../styles/mixins';
import { fontWeight, spacer } from '../styles/variables';
import styled from '@emotion/styled';
import { breakpointUp } from '../styles/mq';

const StyledTitle = styled.h1`
	font-size: ${getRemSize(28)};
	margin-bottom: ${spacer['7']};

	${breakpointUp('md')} {
		font-size: ${getRemSize(44)};
		margin-bottom: ${spacer['9']};
	}
`;

const StyledSubtitle = styled.small`
	display: block;
	font-size: 45%;
	opacity: 0.5;
	font-weight: ${fontWeight.normal};

	${breakpointUp('md')} {
		//margin-top: ${spacer['2']};
	}
`;

type Props = {
	title: string;
	subtitle?: string;
};

const PageTitle: React.FC<Props> = ({ title, subtitle }) => {
	return (
		<StyledTitle>
			{title}
			{subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
		</StyledTitle>
	);
};

export default PageTitle;
