import React from 'react';
import styled from '@emotion/styled';
import { breakpointUp } from '../styles/mq';
import { color, fontSize, spacer } from '../styles/variables';
import { getRemSize } from '../styles/mixins';
import { GatsbyImage } from 'gatsby-plugin-image';

const StyledContact = styled.div`
	display: flex;
	flex-direction: column;

	${breakpointUp('lg')} {
		flex-direction: row;
	}
`;

const StyledImgContainer = styled.div`
	width: ${getRemSize(120)};
	height: ${getRemSize(120)};
	margin-bottom: ${spacer['6']};
	margin-right: 0;
	border-radius: ${getRemSize(120)};
	background-color: ${color.gray['16']};
	overflow: hidden;

	${breakpointUp('lg')} {
		margin-right: ${spacer['8']};
		margin-bottom: 0;
	}
`;

const StyledBody = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledTitle = styled.h3`
	margin-bottom: ${spacer['4']};
	font-size: ${fontSize.md};

	${breakpointUp('md')} {
		font-size: ${fontSize.h3};
	}

	> small {
		display: block;
		margin-top: ${getRemSize(2)};
		font-size: ${fontSize.xs};
		color: ${color.textMuted};

		${breakpointUp('md')} {
			font-size: ${fontSize.sm};
		}
	}
`;

type Props = {
	name: string;
	companyFunction: string;
	phone: string;
	email: string;
	image?: FixedObject;
};

const Contact: React.FC<Props> = ({ name, companyFunction, image, phone, email }) => {
	return (
		<StyledContact>
			<StyledImgContainer>{image && (
				<GatsbyImage
					image={image}
					fadeIn={false}
					alt={name}
				/>
			)}</StyledImgContainer>
			<StyledBody>
				<StyledTitle>
					{name}
					<small>{companyFunction}</small>
				</StyledTitle>
				<a href={`tel:${phone.replace(/\s+/g, '')}`}>{phone}</a>
				<a href={`mailto:${email}`}>{email}</a>
			</StyledBody>
		</StyledContact>
	);
};

export default Contact;
